import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"

export default function FourOhFourPage() {
  useEffect(() => {
    navigate("/")
  }, [])

  return null
}
